import { render, staticRenderFns } from "./foreign-visits.vue?vue&type=template&id=439610ee"
import script from "./foreign-visits.vue?vue&type=script&lang=ts"
export * from "./foreign-visits.vue?vue&type=script&lang=ts"
import style0 from "./foreign-visits.vue?vue&type=style&index=0&id=439610ee&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports